import 'rc-slider/assets/index.css';
import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import Slider from 'rc-slider';
import config from '@p14/config';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Component = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

export const Root = styled.div`
  width: 230px;
`;

export const Input = styled(Component)`
  .rc-slider-handle {
    border-color: ${config.colors.main} !important;
    box-shadow: none !important;
  }

  .rc-slider-track {
    background-color: ${config.colors.main};
  }
`;

export const Range = ({min, max, value, onChange}) => (
  <Root>
    <Input min={min} max={max} value={value} pushable={40} onChange={onChange}/>
  </Root>
);

Range.propTypes = {};

export default Range;
