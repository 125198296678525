import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import {compose, withHandlers, withState, onlyUpdateForPropTypes, setPropTypes, defaultProps} from 'recompose';
import {range} from 'ramda';
import config from '@p14/config';
import * as Modules from './_';
import * as Components from '@p14/components';

export const Root = styled.aside`
  flex: 1 1 237px;
  margin-right: 83px;

  @media ${config.media.tabletOnly} {
    margin-right: 30px;
  }

  @media ${config.media.mobile} {
    width: 100%;
    margin: 0 0 20px;
  }
`;

export const Block = styled.section`
  margin-bottom: 40px;

  &:first-child {
    margin-bottom: 80px;

    @media ${config.media.mobile} {
      margin-bottom: 20px;
    }
  }

  @media ${config.media.mobile} {
    margin-bottom: 20px;
  }
`;

export const BlockTitle = styled.h2`
  margin: 0 0 12px;
  color: ${config.colors.main};
  font-size: 16px;
  font-weight: bold;
  font-family: ${config.fonts.serif};
`;

export const BlockContainer = styled.div``;

export const Aside = ({area, type, bedrooms, types, floors, onFloorChange, onBedroomChange, onTypeChange, onAreaChange}) => (
  <Root>
    <Block>
      <BlockTitle>Этаж</BlockTitle>
      <BlockContainer>
        {floors.map(floor => (
          <Modules.Checkbox
            name="floor"
            key={floor.value}
            label={floor.value}
            isChecked={floor.isChecked}
            onChange={onFloorChange}
            />
        ))}
      </BlockContainer>
    </Block>
    <Block>
      <BlockTitle>Площадь</BlockTitle>
      <Modules.Range min={20} max={300} value={area} onChange={onAreaChange}/>
    </Block>
    <Block>
      <BlockTitle>Количество спален</BlockTitle>
      <BlockContainer>
        {bedrooms.map(bedroom => (
          <Modules.Checkbox
            name="bedroom"
            key={bedroom.value}
            label={bedroom.value}
            isChecked={bedroom.isChecked}
            onChange={onBedroomChange}
            />
        ))}
      </BlockContainer>
    </Block>
    <Block>
      {types.map(t => (
        <Components.Radio
          key={t.value}
          value={t.value}
          label={t.label}
          isChecked={t.isChecked}
          onChange={onTypeChange}
          />
      ))}
    </Block>
  </Root>
);


export const enhance = compose(
  setPropTypes({
    types: T.arrayOf(T.shape({
      label: T.string.isRequired,
      value: T.string.isRequired,
      isChecked: T.bool.isRequired,
    }).isRequired).isRequired,
    area: T.arrayOf(T.number.isRequired).isRequired,
    floors: T.arrayOf(T.shape({
      value: T.number.isRequired,
      isChecked: T.bool.isRequired,
    }).isRequired).isRequired,
    bedrooms: T.arrayOf(T.shape({
      value: T.number.isRequired,
      isChecked: T.bool.isRequired,
    }).isRequired).isRequired,
    onFloorChange: T.func.isRequired,
    onBedroomChange: T.func.isRequired,
    onTypeChange: T.func.isRequired,
    onAreaChange: T.func.isRequired,
  }),
);

export default enhance(Aside);
