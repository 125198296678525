import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import {compose, withHandlers, setPropTypes, onlyUpdateForPropTypes} from 'recompose';
import config from '@p14/config';

export const Root = styled.label`
  display: inline-block;
  vertical-align: top;
  width: 25px;
  height: 25px;
  margin-bottom: 8px;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
  transition: border-color 0.3s ease 0s;
  will-change: border-color;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    border-color: ${config.colors.main};
  }
`;

export const Label = styled.span`
  font-size: 12px;
  display: block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 100%;
  border: 1px solid ${props => props.isChecked ? 'transparent' : config.colors.text};
  color: ${props => props.isChecked ? `${config.colors.light} !important` : config.colors.text};
  background-color: ${props => props.isChecked ? config.colors.main : 'transparent'};
  transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  will-change: color, background-color, border-color;

  &:hover {
    border-color: ${config.colors.main};
    color: ${config.colors.main};
  }
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
`;

export const Checkbox = ({label, isChecked, onChange, ...other}) => (
  <Root isChecked={isChecked}>
    <Label isChecked={isChecked}>{label}</Label>
    <Input type="checkbox" onChange={onChange} checked={isChecked} {...other}/>
  </Root>
);

export const enhance = compose(
  withHandlers({
    onChange: props => () => props.onChange(props.label),
  }),
  onlyUpdateForPropTypes,
  setPropTypes({
    label: T.number.isRequired,
    isChecked: T.bool.isRequired,
    onChange: T.func.isRequired,
  }),
);

export default enhance(Checkbox);
