import React from 'react';
import T from 'prop-types';
import {compose, withState, setPropTypes, withHandlers} from 'recompose';
import styled from 'styled-components';
import {range} from 'ramda';
import Layout from '@p14/layouts';
import * as HOC from '@p14/HOC';
import * as Modules from './_';
import * as Components from '@p14/components';
import config from '@p14/config';
import data from '@p14/texts/apartments';
import items from '../../../apartments.js';

export const Container = styled(Components.Container)`
  display: flex;
  margin-bottom: 90px;

  @media ${config.media.mobile} {
    display: block;
    margin-bottom: 40px;
  }
`;

export const Footer = styled.p`
  margin: 50px auto 30px;
  max-width: 784px;
  font-family: Geometria;
  font-size: 12px;
  color: #000;
  line-height: 14px;
  text-align: center;
  color: ${config.colors.text};
`;

export const meta = {
  title: 'Апартаменты в доме «Пироговская, 14», 1, 2, 3 спальни и пентхаусы в Хамовниках.',
  keywords: 'Пироговская, 14, купить, апартаменты, выбор, однокомнатные, двухкомнатные, трехкомнатные, пентхаусы, терраса',
  description: 'Варианты апартаментов и пентхаусов с планировками в доме «Пироговская, 14». Продажа одно-, двух-, трехкомнатных апартаментов в историческом районе Хамовники.',
};

export const Apartments = ({
  types,
  area,
  floors,
  bedrooms,
  onFloorChange,
  onBedroomChange,
  onTypeChange,
  onAreaChange,
  tr,
}) => {
  const activeFloors = floors.filter(floor => floor.isChecked).map(floor => floor.value);
  const activeBedrooms = bedrooms.filter(bedroom => bedroom.isChecked).map(bedroom => bedroom.value);
  const activeTypes = types.filter(type => type.isChecked).map(type => type.value);

  return (
    <Layout {...meta}>
      <Components.H1>{tr('title')}</Components.H1>
      <Container>
        <Modules.Aside
          types={types}
          area={area}
          floors={floors}
          bedrooms={bedrooms}
          onFloorChange={onFloorChange}
          onBedroomChange={onBedroomChange}
          onTypeChange={onTypeChange}
          onAreaChange={onAreaChange}
        />
        <Modules.List
          items={items
              .filter(item => activeFloors.length === 0 ? item : activeFloors.includes(item.floor))
              .filter(item => item.area >= area[0] && item.area <= area[1])
              .filter(item => activeBedrooms.length === 0 ? item : activeBedrooms.includes(item.bedroomCount))
              .filter(item => activeTypes.length === 0 ? item : activeTypes.reduce((acc, v) => item[v], false))}
          />
      </Container>

      <Footer dangerouslySetInnerHTML={{__html: 'Площадь апартамента по проектной документации может быть изменена в порядке, предусмотренном 214<br/>ФЗ «Об участии в долевом строительстве» от 30 декабря 2004 года №210-ФЗ.'}}/>
    </Layout>
  );
};

export const types = [{label: 'Пентхаус', value: 'penthouse'}, {label: 'Терасса', value: 'terrace'}];

export const enhance = compose(
  withState('floors', 'changeFloor', range(2, 10).map(i => ({isChecked: false, value: i}))),
  withState('bedrooms', 'changeBedroom', range(1, 5).map(i => ({isChecked: false, value: i}))),
  withState('types', 'changeType', types.map(type => ({isChecked: false, ...type}))),
  withState('area', 'changeArea', [20, 300]),
  withHandlers({
    onFloorChange: props => floor => props.changeFloor(props.floors.map(f => f.value === floor ? ({...f, isChecked: !f.isChecked}) : f)),
    onBedroomChange: props => bedroom => props.changeBedroom(props.bedrooms.map(b => b.value === bedroom ? ({...b, isChecked: !b.isChecked}) : b)),
    onTypeChange: props => type => props.changeType(props.types.map(t => t.value === type ? ({...t, isChecked: !t.isChecked}) : t)),
    onAreaChange: props => values => props.changeArea(values),
  }),
  HOC.withTranslation(data),
  setPropTypes({
    types: T.arrayOf(T.shape({
      label: T.string.isRequired,
      value: T.string.isRequired,
      isChecked: T.bool.isRequired,
    }).isRequired).isRequired,
    area: T.arrayOf(T.number.isRequired).isRequired,
    floors: T.arrayOf(T.shape({
      value: T.number.isRequired,
      isChecked: T.bool.isRequired,
    }).isRequired).isRequired,
    bedrooms: T.arrayOf(T.shape({
      value: T.number.isRequired,
      isChecked: T.bool.isRequired,
    }).isRequired).isRequired,
    onFloorChange: T.func.isRequired,
    onBedroomChange: T.func.isRequired,
    onTypeChange: T.func.isRequired,
    onAreaChange: T.func.isRequired,
  }),
);

export default enhance(Apartments);
